interface EventBus {
  eventObject: { [key: string]: ((...args) => any)[] };

  on(eventName: string, callback: (...args) => any): void;
  emit(eventName: string, ...args): void;
}

class EventBus implements EventBus {
  constructor() {
    // 初始化事件列表
    this.eventObject = {};
  }
  // 发布事件
  emit(eventName: string, ...args) {
    // 取出当前事件所有的回调函数
    const callbackList = this.eventObject[eventName];

    if (!callbackList) return console.warn(eventName + " not found!");

    // 执行每一个回调函数
    for (let callback of callbackList) {
      // 执行时传入参数
      callback(...args);
    }
  }
  // 订阅事件
  on(eventName: string, callback: (...args) => any) {
    // 初始化这个事件
    if (!this.eventObject[eventName]) {
      this.eventObject[eventName] = [];
    }

    // 存储订阅者的回调函数
    this.eventObject[eventName].push(callback);
  }
}

const Bus = new EventBus();
export default Bus;
