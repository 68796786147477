// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file proxy.proto (package trpc.servicedesk.servicedesk_proxy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * Login Message
 *
 * @generated from message trpc.servicedesk.servicedesk_proxy.LoginReq
 */
export class LoginReq extends Message<LoginReq> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: string taihu_state = 2;
   */
  taihuState = "";

  constructor(data?: PartialMessage<LoginReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.LoginReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "taihu_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginReq {
    return new LoginReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginReq {
    return new LoginReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginReq {
    return new LoginReq().fromJsonString(jsonString, options);
  }

  static equals(a: LoginReq | PlainMessage<LoginReq> | undefined, b: LoginReq | PlainMessage<LoginReq> | undefined): boolean {
    return proto3.util.equals(LoginReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.LoginRsp
 */
export class LoginRsp extends Message<LoginRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.servicedesk.servicedesk_proxy.LoginRsp.Data data = 3;
   */
  data?: LoginRsp_Data;

  constructor(data?: PartialMessage<LoginRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.LoginRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: LoginRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginRsp {
    return new LoginRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginRsp {
    return new LoginRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginRsp {
    return new LoginRsp().fromJsonString(jsonString, options);
  }

  static equals(a: LoginRsp | PlainMessage<LoginRsp> | undefined, b: LoginRsp | PlainMessage<LoginRsp> | undefined): boolean {
    return proto3.util.equals(LoginRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.LoginRsp.Data
 */
export class LoginRsp_Data extends Message<LoginRsp_Data> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: repeated trpc.servicedesk.servicedesk_proxy.LoginRsp.Data.ProjData proj_list = 2;
   */
  projList: LoginRsp_Data_ProjData[] = [];

  constructor(data?: PartialMessage<LoginRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.LoginRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "proj_list", kind: "message", T: LoginRsp_Data_ProjData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginRsp_Data {
    return new LoginRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginRsp_Data {
    return new LoginRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginRsp_Data {
    return new LoginRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: LoginRsp_Data | PlainMessage<LoginRsp_Data> | undefined, b: LoginRsp_Data | PlainMessage<LoginRsp_Data> | undefined): boolean {
    return proto3.util.equals(LoginRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.LoginRsp.Data.ProjData
 */
export class LoginRsp_Data_ProjData extends Message<LoginRsp_Data_ProjData> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: string chn_name = 2;
   */
  chnName = "";

  /**
   * @generated from field: string user_sig = 3;
   */
  userSig = "";

  /**
   * @generated from field: int32 sdk_appid = 4;
   */
  sdkAppid = 0;

  /**
   * @generated from field: int32 proj_id = 5;
   */
  projId = 0;

  /**
   * @generated from field: string helper_id = 6;
   */
  helperId = "";

  /**
   * @generated from field: string proj_eng_name = 7;
   */
  projEngName = "";

  /**
   * @generated from field: string proj_chn_name = 8;
   */
  projChnName = "";

  constructor(data?: PartialMessage<LoginRsp_Data_ProjData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.LoginRsp.Data.ProjData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chn_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_sig", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sdk_appid", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "proj_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "helper_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "proj_eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "proj_chn_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginRsp_Data_ProjData {
    return new LoginRsp_Data_ProjData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginRsp_Data_ProjData {
    return new LoginRsp_Data_ProjData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginRsp_Data_ProjData {
    return new LoginRsp_Data_ProjData().fromJsonString(jsonString, options);
  }

  static equals(a: LoginRsp_Data_ProjData | PlainMessage<LoginRsp_Data_ProjData> | undefined, b: LoginRsp_Data_ProjData | PlainMessage<LoginRsp_Data_ProjData> | undefined): boolean {
    return proto3.util.equals(LoginRsp_Data_ProjData, a, b);
  }
}

/**
 * LogOff Message
 *
 * @generated from message trpc.servicedesk.servicedesk_proxy.LogOffReq
 */
export class LogOffReq extends Message<LogOffReq> {
  constructor(data?: PartialMessage<LogOffReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.LogOffReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogOffReq {
    return new LogOffReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogOffReq {
    return new LogOffReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogOffReq {
    return new LogOffReq().fromJsonString(jsonString, options);
  }

  static equals(a: LogOffReq | PlainMessage<LogOffReq> | undefined, b: LogOffReq | PlainMessage<LogOffReq> | undefined): boolean {
    return proto3.util.equals(LogOffReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.LogOffRsp
 */
export class LogOffRsp extends Message<LogOffRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.servicedesk.servicedesk_proxy.LogOffRsp.Data data = 3;
   */
  data?: LogOffRsp_Data;

  constructor(data?: PartialMessage<LogOffRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.LogOffRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: LogOffRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogOffRsp {
    return new LogOffRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogOffRsp {
    return new LogOffRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogOffRsp {
    return new LogOffRsp().fromJsonString(jsonString, options);
  }

  static equals(a: LogOffRsp | PlainMessage<LogOffRsp> | undefined, b: LogOffRsp | PlainMessage<LogOffRsp> | undefined): boolean {
    return proto3.util.equals(LogOffRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.LogOffRsp.Data
 */
export class LogOffRsp_Data extends Message<LogOffRsp_Data> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<LogOffRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.LogOffRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogOffRsp_Data {
    return new LogOffRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogOffRsp_Data {
    return new LogOffRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogOffRsp_Data {
    return new LogOffRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: LogOffRsp_Data | PlainMessage<LogOffRsp_Data> | undefined, b: LogOffRsp_Data | PlainMessage<LogOffRsp_Data> | undefined): boolean {
    return proto3.util.equals(LogOffRsp_Data, a, b);
  }
}

/**
 * QueryStaff Message
 *
 * @generated from message trpc.servicedesk.servicedesk_proxy.QueryStaffReq
 */
export class QueryStaffReq extends Message<QueryStaffReq> {
  constructor(data?: PartialMessage<QueryStaffReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.QueryStaffReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryStaffReq {
    return new QueryStaffReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryStaffReq {
    return new QueryStaffReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryStaffReq {
    return new QueryStaffReq().fromJsonString(jsonString, options);
  }

  static equals(a: QueryStaffReq | PlainMessage<QueryStaffReq> | undefined, b: QueryStaffReq | PlainMessage<QueryStaffReq> | undefined): boolean {
    return proto3.util.equals(QueryStaffReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.QueryStaffRsp
 */
export class QueryStaffRsp extends Message<QueryStaffRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.servicedesk.servicedesk_proxy.QueryStaffRsp.Staff data = 3;
   */
  data: QueryStaffRsp_Staff[] = [];

  constructor(data?: PartialMessage<QueryStaffRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.QueryStaffRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: QueryStaffRsp_Staff, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryStaffRsp {
    return new QueryStaffRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryStaffRsp {
    return new QueryStaffRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryStaffRsp {
    return new QueryStaffRsp().fromJsonString(jsonString, options);
  }

  static equals(a: QueryStaffRsp | PlainMessage<QueryStaffRsp> | undefined, b: QueryStaffRsp | PlainMessage<QueryStaffRsp> | undefined): boolean {
    return proto3.util.equals(QueryStaffRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.QueryStaffRsp.Staff
 */
export class QueryStaffRsp_Staff extends Message<QueryStaffRsp_Staff> {
  /**
   * @generated from field: string eng_name = 1;
   */
  engName = "";

  /**
   * @generated from field: string chn_name = 2;
   */
  chnName = "";

  constructor(data?: PartialMessage<QueryStaffRsp_Staff>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.QueryStaffRsp.Staff";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chn_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryStaffRsp_Staff {
    return new QueryStaffRsp_Staff().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryStaffRsp_Staff {
    return new QueryStaffRsp_Staff().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryStaffRsp_Staff {
    return new QueryStaffRsp_Staff().fromJsonString(jsonString, options);
  }

  static equals(a: QueryStaffRsp_Staff | PlainMessage<QueryStaffRsp_Staff> | undefined, b: QueryStaffRsp_Staff | PlainMessage<QueryStaffRsp_Staff> | undefined): boolean {
    return proto3.util.equals(QueryStaffRsp_Staff, a, b);
  }
}

/**
 * Proxy Message
 *
 * @generated from message trpc.servicedesk.servicedesk_proxy.DoRequestReq
 */
export class DoRequestReq extends Message<DoRequestReq> {
  /**
   * @generated from field: string service = 1;
   */
  service = "";

  /**
   * @generated from field: string method = 2;
   */
  method = "";

  /**
   * @generated from field: string interface_name = 3;
   */
  interfaceName = "";

  /**
   * @generated from field: string post_body = 4;
   */
  postBody = "";

  constructor(data?: PartialMessage<DoRequestReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.DoRequestReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "service", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "interface_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "post_body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DoRequestReq {
    return new DoRequestReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DoRequestReq {
    return new DoRequestReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DoRequestReq {
    return new DoRequestReq().fromJsonString(jsonString, options);
  }

  static equals(a: DoRequestReq | PlainMessage<DoRequestReq> | undefined, b: DoRequestReq | PlainMessage<DoRequestReq> | undefined): boolean {
    return proto3.util.equals(DoRequestReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.DoRequestRsp
 */
export class DoRequestRsp extends Message<DoRequestRsp> {
  /**
   * @generated from field: string rsp = 1;
   */
  rsp = "";

  constructor(data?: PartialMessage<DoRequestRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.DoRequestRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rsp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DoRequestRsp {
    return new DoRequestRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DoRequestRsp {
    return new DoRequestRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DoRequestRsp {
    return new DoRequestRsp().fromJsonString(jsonString, options);
  }

  static equals(a: DoRequestRsp | PlainMessage<DoRequestRsp> | undefined, b: DoRequestRsp | PlainMessage<DoRequestRsp> | undefined): boolean {
    return proto3.util.equals(DoRequestRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.GetWebGlobalSessionIdReq
 */
export class GetWebGlobalSessionIdReq extends Message<GetWebGlobalSessionIdReq> {
  /**
   * @generated from field: string proj_id = 1;
   */
  projId = "";

  constructor(data?: PartialMessage<GetWebGlobalSessionIdReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.GetWebGlobalSessionIdReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWebGlobalSessionIdReq {
    return new GetWebGlobalSessionIdReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWebGlobalSessionIdReq {
    return new GetWebGlobalSessionIdReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWebGlobalSessionIdReq {
    return new GetWebGlobalSessionIdReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetWebGlobalSessionIdReq | PlainMessage<GetWebGlobalSessionIdReq> | undefined, b: GetWebGlobalSessionIdReq | PlainMessage<GetWebGlobalSessionIdReq> | undefined): boolean {
    return proto3.util.equals(GetWebGlobalSessionIdReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.GetWebGlobalSessionIdRsp
 */
export class GetWebGlobalSessionIdRsp extends Message<GetWebGlobalSessionIdRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: string session_id = 3;
   */
  sessionId = "";

  constructor(data?: PartialMessage<GetWebGlobalSessionIdRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.GetWebGlobalSessionIdRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWebGlobalSessionIdRsp {
    return new GetWebGlobalSessionIdRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWebGlobalSessionIdRsp {
    return new GetWebGlobalSessionIdRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWebGlobalSessionIdRsp {
    return new GetWebGlobalSessionIdRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetWebGlobalSessionIdRsp | PlainMessage<GetWebGlobalSessionIdRsp> | undefined, b: GetWebGlobalSessionIdRsp | PlainMessage<GetWebGlobalSessionIdRsp> | undefined): boolean {
    return proto3.util.equals(GetWebGlobalSessionIdRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.KnowledgeSearchReq
 */
export class KnowledgeSearchReq extends Message<KnowledgeSearchReq> {
  /**
   * @generated from field: string query = 1;
   */
  query = "";

  /**
   * @generated from field: string isearch_id = 2;
   */
  isearchId = "";

  /**
   * @generated from field: int32 offset = 3;
   */
  offset = 0;

  /**
   * @generated from field: int32 limit = 4;
   */
  limit = 0;

  /**
   * @generated from field: string proj_id = 5;
   */
  projId = "";

  constructor(data?: PartialMessage<KnowledgeSearchReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.KnowledgeSearchReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "isearch_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "proj_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeSearchReq {
    return new KnowledgeSearchReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeSearchReq {
    return new KnowledgeSearchReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeSearchReq {
    return new KnowledgeSearchReq().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeSearchReq | PlainMessage<KnowledgeSearchReq> | undefined, b: KnowledgeSearchReq | PlainMessage<KnowledgeSearchReq> | undefined): boolean {
    return proto3.util.equals(KnowledgeSearchReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp
 */
export class KnowledgeSearchRsp extends Message<KnowledgeSearchRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData data = 3;
   */
  data?: KnowledgeSearchRsp_KnowledgeSearchData;

  /**
   * @generated from field: string request_id = 4;
   */
  requestId = "";

  constructor(data?: PartialMessage<KnowledgeSearchRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: KnowledgeSearchRsp_KnowledgeSearchData },
    { no: 4, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeSearchRsp {
    return new KnowledgeSearchRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeSearchRsp {
    return new KnowledgeSearchRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeSearchRsp {
    return new KnowledgeSearchRsp().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeSearchRsp | PlainMessage<KnowledgeSearchRsp> | undefined, b: KnowledgeSearchRsp | PlainMessage<KnowledgeSearchRsp> | undefined): boolean {
    return proto3.util.equals(KnowledgeSearchRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData
 */
export class KnowledgeSearchRsp_KnowledgeSearchData extends Message<KnowledgeSearchRsp_KnowledgeSearchData> {
  /**
   * @generated from field: int32 total = 1;
   */
  total = 0;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  /**
   * @generated from field: int32 next_offset = 3;
   */
  nextOffset = 0;

  /**
   * @generated from field: int32 took = 4;
   */
  took = 0;

  /**
   * @generated from field: string isearch_id = 5;
   */
  isearchId = "";

  /**
   * @generated from field: repeated string search_type = 6;
   */
  searchType: string[] = [];

  /**
   * @generated from field: repeated trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData.Content contents = 7;
   */
  contents: KnowledgeSearchRsp_KnowledgeSearchData_Content[] = [];

  constructor(data?: PartialMessage<KnowledgeSearchRsp_KnowledgeSearchData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "next_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "took", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "isearch_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "search_type", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "contents", kind: "message", T: KnowledgeSearchRsp_KnowledgeSearchData_Content, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData {
    return new KnowledgeSearchRsp_KnowledgeSearchData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData {
    return new KnowledgeSearchRsp_KnowledgeSearchData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData {
    return new KnowledgeSearchRsp_KnowledgeSearchData().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeSearchRsp_KnowledgeSearchData | PlainMessage<KnowledgeSearchRsp_KnowledgeSearchData> | undefined, b: KnowledgeSearchRsp_KnowledgeSearchData | PlainMessage<KnowledgeSearchRsp_KnowledgeSearchData> | undefined): boolean {
    return proto3.util.equals(KnowledgeSearchRsp_KnowledgeSearchData, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData.Content
 */
export class KnowledgeSearchRsp_KnowledgeSearchData_Content extends Message<KnowledgeSearchRsp_KnowledgeSearchData_Content> {
  /**
   * @generated from field: string content_source = 1;
   */
  contentSource = "";

  /**
   * @generated from field: string content_type = 2;
   */
  contentType = "";

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: string href = 5;
   */
  href = "";

  /**
   * @generated from field: string thumbnail = 6;
   */
  thumbnail = "";

  /**
   * @generated from field: string author = 7;
   */
  author = "";

  /**
   * @generated from field: string author_display = 8;
   */
  authorDisplay = "";

  /**
   * @generated from field: trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData.Content.ContentExtand extend = 9;
   */
  extend?: KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand;

  /**
   * @generated from field: int32 count_view = 10;
   */
  countView = 0;

  /**
   * @generated from field: int32 count_like = 11;
   */
  countLike = 0;

  /**
   * @generated from field: int32 count_comment = 12;
   */
  countComment = 0;

  /**
   * @generated from field: int32 count_collection = 13;
   */
  countCollection = 0;

  /**
   * @generated from field: bool mobile = 14;
   */
  mobile = false;

  /**
   * @generated from field: bool limited = 15;
   */
  limited = false;

  /**
   * @generated from field: string create_time = 16;
   */
  createTime = "";

  /**
   * @generated from field: string modify_time = 17;
   */
  modifyTime = "";

  /**
   * @generated from field: string fragment = 18;
   */
  fragment = "";

  /**
   * @generated from field: repeated string labels = 19;
   */
  labels: string[] = [];

  constructor(data?: PartialMessage<KnowledgeSearchRsp_KnowledgeSearchData_Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "href", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "thumbnail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "author", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "author_display", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "extend", kind: "message", T: KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand },
    { no: 10, name: "count_view", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "count_like", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "count_comment", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "count_collection", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "mobile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "limited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "create_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "modify_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "fragment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData_Content {
    return new KnowledgeSearchRsp_KnowledgeSearchData_Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData_Content {
    return new KnowledgeSearchRsp_KnowledgeSearchData_Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData_Content {
    return new KnowledgeSearchRsp_KnowledgeSearchData_Content().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeSearchRsp_KnowledgeSearchData_Content | PlainMessage<KnowledgeSearchRsp_KnowledgeSearchData_Content> | undefined, b: KnowledgeSearchRsp_KnowledgeSearchData_Content | PlainMessage<KnowledgeSearchRsp_KnowledgeSearchData_Content> | undefined): boolean {
    return proto3.util.equals(KnowledgeSearchRsp_KnowledgeSearchData_Content, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData.Content.ContentExtand
 */
export class KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand extends Message<KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand> {
  /**
   * @generated from field: string doc_type = 1;
   */
  docType = "";

  /**
   * @generated from field: string modifier = 2;
   */
  modifier = "";

  /**
   * @generated from field: string spaceKey = 3;
   */
  spaceKey = "";

  /**
   * @generated from field: string spaceName = 4;
   */
  spaceName = "";

  /**
   * @generated from field: string modifier_display = 5;
   */
  modifierDisplay = "";

  constructor(data?: PartialMessage<KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.KnowledgeSearchRsp.KnowledgeSearchData.Content.ContentExtand";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "doc_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "modifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "spaceKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "spaceName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "modifier_display", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand {
    return new KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand {
    return new KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand {
    return new KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand | PlainMessage<KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand> | undefined, b: KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand | PlainMessage<KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand> | undefined): boolean {
    return proto3.util.equals(KnowledgeSearchRsp_KnowledgeSearchData_Content_ContentExtand, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.GetCommonQuestionReq
 */
export class GetCommonQuestionReq extends Message<GetCommonQuestionReq> {
  /**
   * @generated from field: int32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: int32 page_number = 2;
   */
  pageNumber = 0;

  /**
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  constructor(data?: PartialMessage<GetCommonQuestionReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.GetCommonQuestionReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommonQuestionReq {
    return new GetCommonQuestionReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommonQuestionReq {
    return new GetCommonQuestionReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommonQuestionReq {
    return new GetCommonQuestionReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommonQuestionReq | PlainMessage<GetCommonQuestionReq> | undefined, b: GetCommonQuestionReq | PlainMessage<GetCommonQuestionReq> | undefined): boolean {
    return proto3.util.equals(GetCommonQuestionReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.GetCommonQuestionRsp
 */
export class GetCommonQuestionRsp extends Message<GetCommonQuestionRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.servicedesk.servicedesk_proxy.GetCommonQuestionRsp.Data data = 3;
   */
  data?: GetCommonQuestionRsp_Data;

  constructor(data?: PartialMessage<GetCommonQuestionRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.GetCommonQuestionRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetCommonQuestionRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommonQuestionRsp {
    return new GetCommonQuestionRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommonQuestionRsp {
    return new GetCommonQuestionRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommonQuestionRsp {
    return new GetCommonQuestionRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommonQuestionRsp | PlainMessage<GetCommonQuestionRsp> | undefined, b: GetCommonQuestionRsp | PlainMessage<GetCommonQuestionRsp> | undefined): boolean {
    return proto3.util.equals(GetCommonQuestionRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.GetCommonQuestionRsp.Data
 */
export class GetCommonQuestionRsp_Data extends Message<GetCommonQuestionRsp_Data> {
  /**
   * @generated from field: int32 total = 1;
   */
  total = 0;

  /**
   * @generated from field: repeated trpc.servicedesk.servicedesk_proxy.GetCommonQuestionRsp.Data.Question questions = 2;
   */
  questions: GetCommonQuestionRsp_Data_Question[] = [];

  constructor(data?: PartialMessage<GetCommonQuestionRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.GetCommonQuestionRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "questions", kind: "message", T: GetCommonQuestionRsp_Data_Question, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommonQuestionRsp_Data {
    return new GetCommonQuestionRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommonQuestionRsp_Data {
    return new GetCommonQuestionRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommonQuestionRsp_Data {
    return new GetCommonQuestionRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommonQuestionRsp_Data | PlainMessage<GetCommonQuestionRsp_Data> | undefined, b: GetCommonQuestionRsp_Data | PlainMessage<GetCommonQuestionRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetCommonQuestionRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.GetCommonQuestionRsp.Data.Question
 */
export class GetCommonQuestionRsp_Data_Question extends Message<GetCommonQuestionRsp_Data_Question> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string question = 2;
   */
  question = "";

  constructor(data?: PartialMessage<GetCommonQuestionRsp_Data_Question>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.GetCommonQuestionRsp.Data.Question";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommonQuestionRsp_Data_Question {
    return new GetCommonQuestionRsp_Data_Question().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommonQuestionRsp_Data_Question {
    return new GetCommonQuestionRsp_Data_Question().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommonQuestionRsp_Data_Question {
    return new GetCommonQuestionRsp_Data_Question().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommonQuestionRsp_Data_Question | PlainMessage<GetCommonQuestionRsp_Data_Question> | undefined, b: GetCommonQuestionRsp_Data_Question | PlainMessage<GetCommonQuestionRsp_Data_Question> | undefined): boolean {
    return proto3.util.equals(GetCommonQuestionRsp_Data_Question, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.KnowledgeSearchHistoryReq
 */
export class KnowledgeSearchHistoryReq extends Message<KnowledgeSearchHistoryReq> {
  /**
   * @generated from field: int32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: int32 page_number = 2;
   */
  pageNumber = 0;

  /**
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  constructor(data?: PartialMessage<KnowledgeSearchHistoryReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.KnowledgeSearchHistoryReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeSearchHistoryReq {
    return new KnowledgeSearchHistoryReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeSearchHistoryReq {
    return new KnowledgeSearchHistoryReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeSearchHistoryReq {
    return new KnowledgeSearchHistoryReq().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeSearchHistoryReq | PlainMessage<KnowledgeSearchHistoryReq> | undefined, b: KnowledgeSearchHistoryReq | PlainMessage<KnowledgeSearchHistoryReq> | undefined): boolean {
    return proto3.util.equals(KnowledgeSearchHistoryReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.KnowledgeSearchHistoryRsp
 */
export class KnowledgeSearchHistoryRsp extends Message<KnowledgeSearchHistoryRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.servicedesk.servicedesk_proxy.KnowledgeSearchHistoryRsp.Data data = 3;
   */
  data: KnowledgeSearchHistoryRsp_Data[] = [];

  constructor(data?: PartialMessage<KnowledgeSearchHistoryRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.KnowledgeSearchHistoryRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: KnowledgeSearchHistoryRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeSearchHistoryRsp {
    return new KnowledgeSearchHistoryRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeSearchHistoryRsp {
    return new KnowledgeSearchHistoryRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeSearchHistoryRsp {
    return new KnowledgeSearchHistoryRsp().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeSearchHistoryRsp | PlainMessage<KnowledgeSearchHistoryRsp> | undefined, b: KnowledgeSearchHistoryRsp | PlainMessage<KnowledgeSearchHistoryRsp> | undefined): boolean {
    return proto3.util.equals(KnowledgeSearchHistoryRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.KnowledgeSearchHistoryRsp.Data
 */
export class KnowledgeSearchHistoryRsp_Data extends Message<KnowledgeSearchHistoryRsp_Data> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string question = 2;
   */
  question = "";

  constructor(data?: PartialMessage<KnowledgeSearchHistoryRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.KnowledgeSearchHistoryRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeSearchHistoryRsp_Data {
    return new KnowledgeSearchHistoryRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeSearchHistoryRsp_Data {
    return new KnowledgeSearchHistoryRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeSearchHistoryRsp_Data {
    return new KnowledgeSearchHistoryRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeSearchHistoryRsp_Data | PlainMessage<KnowledgeSearchHistoryRsp_Data> | undefined, b: KnowledgeSearchHistoryRsp_Data | PlainMessage<KnowledgeSearchHistoryRsp_Data> | undefined): boolean {
    return proto3.util.equals(KnowledgeSearchHistoryRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.DelKnowledgeSearchReq
 */
export class DelKnowledgeSearchReq extends Message<DelKnowledgeSearchReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: int32 del_question_id = 2;
   */
  delQuestionId = 0;

  /**
   * @generated from field: bool is_del_all = 3;
   */
  isDelAll = false;

  constructor(data?: PartialMessage<DelKnowledgeSearchReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.DelKnowledgeSearchReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "del_question_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "is_del_all", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DelKnowledgeSearchReq {
    return new DelKnowledgeSearchReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DelKnowledgeSearchReq {
    return new DelKnowledgeSearchReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DelKnowledgeSearchReq {
    return new DelKnowledgeSearchReq().fromJsonString(jsonString, options);
  }

  static equals(a: DelKnowledgeSearchReq | PlainMessage<DelKnowledgeSearchReq> | undefined, b: DelKnowledgeSearchReq | PlainMessage<DelKnowledgeSearchReq> | undefined): boolean {
    return proto3.util.equals(DelKnowledgeSearchReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.DelKnowledgeSearchRsp
 */
export class DelKnowledgeSearchRsp extends Message<DelKnowledgeSearchRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<DelKnowledgeSearchRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.DelKnowledgeSearchRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DelKnowledgeSearchRsp {
    return new DelKnowledgeSearchRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DelKnowledgeSearchRsp {
    return new DelKnowledgeSearchRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DelKnowledgeSearchRsp {
    return new DelKnowledgeSearchRsp().fromJsonString(jsonString, options);
  }

  static equals(a: DelKnowledgeSearchRsp | PlainMessage<DelKnowledgeSearchRsp> | undefined, b: DelKnowledgeSearchRsp | PlainMessage<DelKnowledgeSearchRsp> | undefined): boolean {
    return proto3.util.equals(DelKnowledgeSearchRsp, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.FeedbackFromBkReq
 */
export class FeedbackFromBkReq extends Message<FeedbackFromBkReq> {
  /**
   * @generated from field: string bk_id = 1;
   */
  bkId = "";

  /**
   * @generated from field: string bk_question = 2;
   */
  bkQuestion = "";

  constructor(data?: PartialMessage<FeedbackFromBkReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.FeedbackFromBkReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bk_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bk_question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeedbackFromBkReq {
    return new FeedbackFromBkReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeedbackFromBkReq {
    return new FeedbackFromBkReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeedbackFromBkReq {
    return new FeedbackFromBkReq().fromJsonString(jsonString, options);
  }

  static equals(a: FeedbackFromBkReq | PlainMessage<FeedbackFromBkReq> | undefined, b: FeedbackFromBkReq | PlainMessage<FeedbackFromBkReq> | undefined): boolean {
    return proto3.util.equals(FeedbackFromBkReq, a, b);
  }
}

/**
 * @generated from message trpc.servicedesk.servicedesk_proxy.FeedbackFromBkRsp
 */
export class FeedbackFromBkRsp extends Message<FeedbackFromBkRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<FeedbackFromBkRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.servicedesk.servicedesk_proxy.FeedbackFromBkRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeedbackFromBkRsp {
    return new FeedbackFromBkRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeedbackFromBkRsp {
    return new FeedbackFromBkRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeedbackFromBkRsp {
    return new FeedbackFromBkRsp().fromJsonString(jsonString, options);
  }

  static equals(a: FeedbackFromBkRsp | PlainMessage<FeedbackFromBkRsp> | undefined, b: FeedbackFromBkRsp | PlainMessage<FeedbackFromBkRsp> | undefined): boolean {
    return proto3.util.equals(FeedbackFromBkRsp, a, b);
  }
}

