import { ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { LoginRsp_Data_ProjData } from '@/gen/proxy_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import RpcService from '@/network/services';
import { useChatStore } from './chat';

export type User = PartialMessage<LoginRsp_Data_ProjData>;

export const useUserStore = defineStore('user', () => {
  const { chatInstance } = storeToRefs(useChatStore());

  // State
  const projListRef = ref<User[]>([]);
  const userRef = ref<User | null>({});

  // Getters
  const user = computed(() => userRef.value);
  const projList = computed(() => projListRef.value);
  const hasUserInfo = computed(() => user && user.value && user.value.userSig);

  // Setters
  const setCurrentProj = (proj: User | null) => userRef.value = proj;

  // Actions
  /**
   * Login and return user info
   * @param string code 
   * @param string taihuState 
   * @returns User
   */
  async function login(code: string, taihuState?: string): Promise<User | null> {
    const rsp = await RpcService.login({ code, taihuState });
    projListRef.value = rsp.data.projList;

    if (projList.value.length === 1) userRef.value = projList.value[0];

    return user.value;
  }
  /**
   * Logout both tai and TUIChat
   */
  async function logout() {
    if (chatInstance.value) await chatInstance.value.logout();
    logoutTai();
  }

  /**
   * Logout tai only
   */
  async function logoutTai() {
    const rsp = await RpcService.logout({});
    userRef.value = null;
    window.location.replace(rsp.data.url);
  }

  return { user, projList, hasUserInfo, login, logout, logoutTai, setCurrentProj }
})
