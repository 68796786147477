/**
 * All Api path, don't need to add /servicedesk-api, cuz it's included in BASE_URL
 * Plz use Record to declare, which ts can infer the row key.
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-9.html#the-satisfies-operator
 */
const api: Record<string, string> = {
  proxy: '/trpc.servicedesk.servicedesk_proxy.Proxy/DoRequest',
  login: '/trpc.servicedesk.servicedesk_proxy.Proxy/Login',
  logOff: '/trpc.servicedesk.servicedesk_proxy.Proxy/LogOff',
  getOffShoreConfig: '/trpc.servicedesk.servicedesk_proxy.Config/GetOffShoreConfig',
  queryStaff: '/trpc.servicedesk.servicedesk_proxy.Proxy/QueryStaff',
  queryDept: '/trpc.servicedesk.servicedesk_proxy.Proxy/QueryDept',
  getLinkageRules: '/trpc.teg_devops.servicedesk_product.Product/GetLinkageRuleConfig',
  doAction: '/trpc.servicedesk.ticket.Ticket/DoAction',
  getTicketDetail: '/trpc.servicedesk.ticket.Ticket/GetTicketDetail',
  tcccCallback: '/trpc.servicedesk.servicedesk_proxy.Proxy/TcccCallback',
  tcccSessionCallback: '/trpc.servicedesk.servicedesk_proxy.Proxy/TcccSessionCallback',
  uploadImActionInfo: '/trpc.servicedesk.ticket.Ticket/UploadImActionInfo',
  queryImActionInfo: '/trpc.servicedesk.ticket.Ticket/QueryImActionInfo',
  uploadFlow:'/trpc.servicedesk.upload_service.UploadService/UploadFlow',
  getWebGlobalSessionId: '/trpc.servicedesk.offshore.Offshore/GetWebGlobalSessionId',
  refreshGlobalSession: '/trpc.servicedesk.offshore.Offshore/RefreshGlobalSession',
  knowledgeSearch: '/trpc.servicedesk.offshore.Offshore/KnowledgeSearch',
  knowledgeSearchHistory: '/trpc.servicedesk.offshore.Offshore/KnowledgeSearchHistory',
  delKnowledgeSearch: '/trpc.servicedesk.offshore.Offshore/DelKnowledgeSearch',
  getCommonQuestion: '/trpc.servicedesk.offshore.Offshore/GetCommonQuestion',
  DoRequest: '/trpc.servicedesk.servicedesk_proxy.Proxy/DoRequest',
  knowledgeChat: '/trpc.servicedesk.yuanfang_service.YuanFangService/KnowledgeChat',
  qaFeedback: '/trpc.servicedesk.yuanfang_service.YuanFangService/QaFeedback',
  feedbackFromBk: '/trpc.servicedesk.servicedesk_proxy.Proxy/FeedbackFromBk'
}

export default api;
