<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="min-h-0 flex flex-1 overflow-auto">
    <div class="flex flex-1 justify-center" style="background: linear-gradient(180deg, rgba(217, 232, 255, 1) 0%, rgba(242, 247, 255, 0.8) 81.06%, rgba(242, 247, 255, 0.5) 99.88%);">
      <div class="w-full h-full overflow-auto scroll-box px-[16px]">
        <router-view v-if="hasUserInfo"></router-view>
      </div>
      <t-popup v-if="showChatIcon" placement="left" :showArrow="true" v-model="visiblePopup">
        <FloatingIcon class="fixed right-[20px] bottom-[100px]" @click="handleClickChatIcon" />
        <template v-slot:content>
          <div class="p-3">
            <div class="text-primary text-lg">IT(小G)在线服务</div>
            <div class="text-gy-text text-xs">专业工程师快速解决您的问题</div>
          </div>
        </template>
      </t-popup>
    </div>
    <ChatPanel ref="chatPanel" v-if="hasUserInfo" @chatPanelVisibilityChange="handleChatPanelVisibilityChange"></ChatPanel>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import FloatingIcon from '@/components/ChatIcon.vue';
import ChatPanel from '@/components/ChatPanel.vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

const router = useRouter();
const chatPanel = ref();
const showChatIcon = ref(true);
const visiblePopup = ref(false);
const handleClickChatIcon = () => {
  visiblePopup.value = false;
  setTimeout(() => {
    chatPanel.value.show();
    showChatIcon.value = false;
  })
}
const handleChatPanelVisibilityChange = (visibility) => {
  showChatIcon.value = !visibility;
}
onMounted(() => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  if (isMobile) router.push({ path: '/chat' });
});

const { hasUserInfo } = storeToRefs(useUserStore());
</script>
