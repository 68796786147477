/**
 * Callback execute right after TUIChatLogin, 
 * return true means callback rigister successfully
 */
const callbackArr = [];
let endRegister = false;
export function onTUIChatLogin(callback: (...args: any[]) => any) {
  if (endRegister) {
    throw Error("Wrong timing to call onTUIChatLogin");
  }
  callbackArr.push(callback);
}

export async function callTUIChatLoginCallbacks() {
  endRegister = true;
  return callbackArr.map(c => c());
}
