import {
  Interceptor,
} from '@connectrpc/connect'
import { createConnectTransport } from '@connectrpc/connect-web'
import { MessagePlugin } from 'tdesign-vue-next';
import { useUserStore } from "@/stores/user";
import { storeToRefs } from 'pinia';
import * as _ from 'lodash';

const proxyInterceptor: Interceptor = (next) => async (req) => {
  try {
    const { user } = storeToRefs(useUserStore());
    // @ts-ignore
    const params = new URLSearchParams({ env: import.meta.env.VITE_APP_ENV });
    if (user.value && user.value.projId) params.append('proj_id', user.value.projId.toString());
    const snakeParams = new URLSearchParams();
    params.forEach((val, key) => {
      snakeParams.append(_.snakeCase(key), val);
    });
    const rsp = await next({
      ...req,
      // @ts-ignore
      url: `${req.url}?${snakeParams.toString()}`
    });
    // @ts-ignore
    if (rsp.message?.code === 15999) {
      // @ts-ignore
      window.location.replace(rsp.message?.data?.url || 'https://test-odc.it.woa.com/odc-login/login')
    }
    return rsp;
  } catch (error) {
    MessagePlugin.error(`Network Error, Code: ${error.code}, Message: ${error.message}`)
  }
}

const defaultTransport = createConnectTransport({
  // Example
  // baseUrl: 'https://demo.connectrpc.com',
  baseUrl: '/servicedesk-api',
  interceptors: [proxyInterceptor]
})

export default defaultTransport;