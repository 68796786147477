import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { TUILogin } from '@tencentcloud/tui-core';
import { StoreName, TUIStore, IConversationModel } from '@tencentcloud/chat-uikit-engine';

export const useChatStore = defineStore('chat', () => {

  /**
   * Only exist after TUIKit.vue mounted lifecycle is invoked.
   * */
  const chatInstance = computed(() => TUILogin.getContext()?.chat);
  const shouldShine = ref(false);
  const shouldShowRedPoint = ref(false);
  const isShowingChatPanel = ref(false);
  const currentConversation = ref<IConversationModel>();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    },
  });

  return {
    chatInstance,
    shouldShine,
    shouldShowRedPoint,
    isShowingChatPanel,
    currentConversation,
  }
})
