import { createRouter, createWebHistory } from 'vue-router'
import Search from '@/views/search/index.vue';
import searchHome from '@/views/search/searchHome.vue';
import searchResult from '@/views/search/searchResult.vue';
import RatePage from '@/views/RatePage.vue';
// import { storeToRefs } from 'pinia';
// import { useUserStore } from '@/stores/user';

const router = createRouter({
  // @ts-ignore
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:projName/search',
      name: 'Search',
      component: Search,
      children: [
        { path: '', name: 'searchHome', component: searchHome, props: true },
        { path: 'result', name: 'searchResult', component: searchResult },
      ]
    }, {
      path: '/:projName/ratepage',
      name: 'RatePage',
      component: RatePage
    }
  ]
})

// router.beforeEach((to, from) => {  
//   if (to.path !== '/') {
//     const userStore = useUserStore();
//     const { setCurrentProj } = userStore;
//     const { user, projList } = storeToRefs(userStore);

//     const projId = to.path.split('/')[1];
//     if (projId && user.value.projId !== Number(projId)) setCurrentProj(projId);
//   } else {
//     const userStore = useUserStore();
//     const { setCurrentProj } = userStore;
//     setCurrentProj(null);
//   }
// });

router.afterEach(() => {
  const url = new URL(location.href);
  if (url.searchParams.has("code")) {
    url.searchParams.delete("code")
    window.history.replaceState({}, null, `${url.pathname}?${url.searchParams.toString()}`);
  }
});

export default router
